/* ProfileCard.css */
.profile-card {
    display: flex;
    align-items: center;
  }
  
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
  }
  