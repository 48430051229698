/* Navbar styles */


/* Navbar styles */


/* Tile styles */
.containertiles{

  background-color: white;
  display: inline-block;
  vertical-align: top;
  margin: 10px;
  width: 300px;
  height: 300px;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
}

.containertiles:hover {
  /* border: 2px solid #4899c5;
  transition: border-width 0.2s ease-in-out; */
  /* box-shadow: inset 0 0 0 5px #4899c5; */
  /* animation: border-pulse .5s ease-out infinite; */
  /* background-color: azure; */

  box-shadow: 0 0 10px 2px #4899c5;
  transition: box-shadow 0.2s ease-in-out;
}

.containertiles:hover .tile-title {
  --s: 0.1em;   /* the thickness of the line */
  --c: #f65d5d; /* the color */
  
  color: #0000;
  padding-bottom: var(--s);
  background: 
    linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
  -webkit-background-clip: text,padding-box;
          background-clip: text,padding-box;
  transition: 0.5s;
  --_p: 100%
}

.containertiles:hover .tile-button  {
  border: 2px solid #f65d5d;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  transform: rotate(360deg);
}

@keyframes border-pulse {
  0% {
    box-shadow: inset 0 0 0 5px #4899c5;
  }
  50% {
    box-shadow: inset 0 0 0 10px #4899c5;
  }
  100% {
    box-shadow: inset 0 0 0 5px #4899c5;
  }
}

.tile-section {
  /* margin-top: 80px; */
  margin-top: 2rem;
  margin-bottom: 5rem;
}

.tile {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  justify-content: space-between;
  height: 300px;
  width: 300px;
  margin: 20px;
  position: relative;
}

.tileimages {
  flex-grow: 1;
  width: 100%;
}

.tile-title {
  /* font-family: 'Montserrat', sans-serif; */
  font-size: 14px;
  font-weight: bold;
  /* margin-top: 20px; */
  padding: 5px;
  height: 50px;
  text-align: center;
  transition: all 0.2s ease-in-out;

}
.tile-H2Home {
  color: #5c5454;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  /* display: inline-block; */
  overflow: hidden;
  padding: 0.5rem 1rem;
  transition: border-color 3s ease-in-out;
  border: 2px solid transparent;
}

.tile-H2Home::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* border: 5px solid #f55d5d; */
  box-sizing: border-box;
  transform: scaleX(0) rotate(0deg);
  transition: transform 3s ease-in-out, border-color 3s ease-in-out;
  transition-delay: 0.6s, 3s;
}

.tile-H2Home:hover::before {
  border-color: #fff;
  transform: scaleX(1) rotate(0deg);
  transition-delay: 0s, 3s;
}



.tile-description {
  /* font-family: 'Open Sans', sans-serif; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 11px;
  height: calc(22ch); /* adjust the height to fit your design */
  margin: 14px;
  text-align: center;
  /* white-space: normal; */

  /* transition: all 0.2s ease-in-out; */
  --s: 0.1em;   /* the thickness of the line */
  --c: #3f51b5; /* the color */
  /* color: red; */
  padding-bottom: var(--s);
  background: 
    linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
  -webkit-background-clip: text,padding-box;
          background-clip: text,padding-box;
  transition: 0.5s;
  width: calc(200ch * 1em); /* The ch unit is equal to the width of the '0' (zero) glyph */
  overflow-wrap: break-word; /* Ensure long words wrap and don't overflow */
  white-space: pre-wrap; /* Ensure whitespace is preserved and content wraps */

}
.tile-description:after {
  content: '...';
  display: inline;
}

 /* .tile-description:hover {--_p: 100%} */

.tile-title, .tile-description {
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tile-title {
  --s: 0.1em;   /* the thickness of the line */
  --c: #f65d5d; /* the color */
  
  color: #0000;
  padding-bottom: var(--s);
  background: 
    linear-gradient(90deg,var(--c) 50%,#000 0) calc(100% - var(--_p,0%))/200% 100%,
    linear-gradient(var(--c) 0 0) 0% 100%/var(--_p,0%) var(--s) no-repeat;
  -webkit-background-clip: text,padding-box;
          background-clip: text,padding-box;
  transition: 0.5s;
}
.tile-title:hover {--_p: 100%}


.tile-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  /* margin-bottom: 20px; */
}

.tile-button {
  background-color: #ffffff;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  color: #333333;
  cursor: pointer;
  height: 50px;
  margin-right: 10px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile-button:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.tile-link {
  color: #ffffff;
  background-color: #333333;
  padding: 10px 20px;
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.tile-link:hover {
  background-color: #666666;
}

.tile-link-container {
  display: flex;
  justify-content: center;
}

.fa-github, .fa-youtube {
  font-size: 24px;
}


/* Profile styles */
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.profile-picture {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
}

.profile-name {
  font-size: 36px;
  margin-bottom: 10px;
}

.profile-bio {
  font-size: 18px;
  text-align: center;
}

/* loadmore starts */

.blogLoadMoreHome:link,
.blogLoadMoreHome:visited {
  /* text-transform: uppercase; */
  /* background-color: aqua; */
  text-decoration: none;
  padding: 12px 35px;
  display: inline-block;
  border-radius: 100px;
  transition: all .2s;
  position: absolute;
  transform-style: preserve-3d;
  transform: translateZ(0);
}

.blogLoadMoreHome:hover {
  transform: translateY(-3px) rotateX(10deg) rotateY(10deg);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.blogLoadMoreHome:active {
  transform: translateY(-1px) rotateX(5deg) rotateY(5deg);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.blogLoadMoreHome-white {
  background-color: #6376ee;
  color: #777;
}

.blogLoadMoreHome::after {
  content: "";
  display: inline-block;
  height: 90%;
  width: 90%;
  border-radius: 100px;
  position: absolute;
  color: white;
  top: 2px;
  left: 2px;
  z-index: -1;
  /* transition: all .4s;
  transform-origin: center; */
}

.blogLoadMoreHome::after {
  /* background-color: #fff; */
  background-color: #1b7372;
  
}

.blogLoadMoreHome:hover::after {
  transform: scaleX(1.4) scaleY(1.6) rotateX(45deg) rotateY(-45deg);
  opacity: 0;
}

.blogLoadMoreHome {
 
  margin-left: 20px;
  /* color: white; */
 
  /* animation: moveInBottom 5s ease-out; */
  animation-fill-mode: backwards;
  color: #fff; 
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.blogLoadMoreHome:hover {
  color: #1b7372;
}

/* loadmore ends */
/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  margin: 5rem 0 2rem 0;
}

.page-item {
  list-style: none;
}

.page-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #4caf50;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
}

.page-link:hover {
  color: #2e7d32;
  background-color: #e8f5e9;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #1f5156;
  border-radius: 50%;
}

.page-link:focus {
  outline: none;
}

/* Arrow styles */
.page-link.prev,
.page-link.next {
  position: relative;
  padding: 0.5rem 0.75rem;
  font-size: 0;
  line-height: 1;
  color: #1f5156;
}

.page-link.prev:before,
.page-link.next:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 0.15rem;
  border-color: currentColor;
  width: 0.75rem;
  height: 0.75rem;
}

.page-link.prev:before {
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
}

.page-link.next:before {
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
}





@media (max-width: 768px) {
  .containertiles {
    margin-left: 3.6rem;
   /* padding: 25px; */
  }
  
}