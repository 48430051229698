.profilecardcontainer{
  margin-top:5rem;
}

@media screen and (max-width: 1050px) {
  .profilecardcontainer{
    margin-top:15%;
  }

}

@media screen and (max-width: 700px) {
  .profilecardcontainer{
    margin-top:15%;
  }

}