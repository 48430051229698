.search-container {
    position: relative;
    display: inline-block;
  }
  .search-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh; /* Set the height to 100% of the viewport */
  }
  
  .search-input {
    width: 250px;
    height: 30px;
    padding: 5px 30px 5px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .search-icon {
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    pointer-events: none;
  }
  
  .clear-button{
    margin-left: 10px;
  }