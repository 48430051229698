.navbar {
    /* background-color: #0d5d65; */

    background-color:  #050d16;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
  }
  
  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
  }
  
  .nav-logo {
    display: flex;
    color: #f5b921;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    flex-grow: 1;
    font-family: "Pacifico";
  }

  .navspan{
    margin-left: 5px;
  }

  
  .nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
  }
  
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.0008rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
  }
  .fa-code {
    margin-left: 1rem;
  }
  
  .nav-item {
    line-height: 40px;
    margin-right: 1rem;
  }
  
  .nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after:not(.nav-aboutmeli) {
    width: 100%;
    background: #ffdd40;
  }

  .nav-item:hover:after:not(.nav-microsoftli) {
    width: 100%;
    background: #ffdd40;
  }
  
  .nav-item .active {
    color: #ffdd40;
    border: 1px solid #ffdd40;
  }
  
  .nav-icon {
    display: none;
  }


/* dropdownstyles start */
.nav-dropdown {
  position: relative;
  display: inline-block;
}

.nav-dropdown-toggle {
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav-dropdown-menu {
  display: none;
  position: absolute;
  /* background-color: #0d5d65; */
  background-color: #030f17;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 10px;
  border-radius: 5px;
  text-align: left;
}

.nav-dropdown-menu .nav-dropdown-item {
  color: white;
  padding: 5px 25px;
  /* text-decoration: underline white;
  text-underline-offset: 1em; */
  font-weight: 500;
  display: block;
  font-size: 16px;
  /* width: 100%; */
  transition: background-color 0.2s;
}

 .nav-dropdown-menu .nav-dropdown-item:hover {
  background-color: white;
  color: #0d5d65;
} 

.nav-dropdown:hover .nav-dropdown-menu {
  display: block;
}

.nav-dropdown-menu.open {
  display: block;
}

/* .nav-dropdown-item.nav-links:not(:hover) {
  display: none;
} */


.nav-container {
  position: relative;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
}

.navbar {
  position: relative;
  z-index: 1;
}


@media screen and (max-width: 960px) {
  .nav-dropdown {
    display: block;
    width: 100%;
  }

  .nav-dropdown-menu.close {   
    display: none!important;
  }
  .nav-dropdown-menu{
    position: static;
    background-color: transparent;
    box-shadow: none;
    margin-top: 0;
    padding-left: 8rem;
  }

  .nav-dropdown-item {
    padding: 8px 16px;
  }
}
/* dropdownstyles end */

/* active menu styles */
.nav-item.active {
  color: #ffdd40;
  border-bottom:4px solid;
}
/* .nav-dropdown-item.nav-links.active{
  color: #ffdd40;
  border-bottom:4px solid;
} */
/* active menu styles end */

  @media screen and (max-width: 960px) {
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 1pxsolid #fff;
      position: absolute;
      top: 80px;
      left: -110%;
      opacity: 1;
      transition: all 0.5s ease;
    }
    .nav-dropdown-menu .nav-dropdown-item {
      color: #f5b921;
    }
  
    .nav-menu.active {
      background: #1f5156;
      left: 0px;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
    .nav-item .active {
      color: #ffdd40;
      border: none;
    }

    .nav-links {
      padding: 1.5rem;
      width: 100%;
      display: table;
    }
  
    .nav-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #ffdd40;
    }
     
    
      nav ul li {
        margin-right: 5rem!important;
       
      }
  }

  
  @media screen and (max-width: 450px) {

    .nav-dropdown-menu{
    
      padding-left: 5rem;
    }

  }